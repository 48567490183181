<template>
	<div
		v-if="fields"
		class="meta"
	>
		<dl>
			<MetaItem
				v-for="{isFilter, name, slug} in fields"
				:key="slug"
				v-bind="{isFilter, name, slug, value: items[slug]}"
			/>
		</dl>
	</div>
</template>

<script>
import {fields} from '@/data/filter';
export default {
	components: {
		MetaItem: () => import(/* webpackChunkName: "MetaItem" */ '@/components/meta/MetaItem'),
	},
	props: {
		items: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		fields() {
			return fields.filter(({slug}) => this.items[slug]);
		}
	}
};
</script>

<style lang="scss">
	.meta {
		dl {
			display: flex;
			flex-wrap: wrap;
			position: relative;
			max-width: 750px;
		}

		dl,
		dd {
			margin: 0;
		}

		&-item {
			flex: 1 1 100%;
			position: relative;
			padding: 0.5rem 1rem 0.5rem 0;

			&.has-icon {
				margin: 0 0 1rem;
				padding-left: 4rem;
				max-width: 250px;
			}

			&.species-description dd {
				font-variant: small-caps;
			}
		}

		&-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			width: 3rem;
			height: 3rem;
			background-color: $color-light;
			border-radius: 50%;
		}
	}
</style>
